// 聊天2个模型
export const basicChatModelNameOld = 'GPT-3.5';
export const basicChatModelName = 'Standard';
export const proChatModelName = 'GPT-4';
// 文档2个模型
export const basicDocModelNameOld = 'GPT-3.5-RAG';
export const basicDocModelName = 'Standard RAG';
export const proDocModelName = 'GPT-4-RAG';
// 聊天第3个模型（不一定有）
export const dallModelName = 'DALL·E 3';

//新增模型
export const WebBrowsingName = 'Web browsing';
// export const gpt4oName = 'GPT-4o';
export const gpt4oName = 'GPT-4o';
// 生图模型
export const ImageGenerationName = 'Image generation';
export const gpto1MiniName = 'o1-mini';
